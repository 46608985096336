.total-wrapper{
    display: block;
}
.title{
    color: rgba(0,0,0,0.54);
}
.table-body-wrapper{
    display: inline;
}
.table-items-header {
    padding-top: 6px;
    padding-right: 24px;
    padding-bottom: 6px;
    padding-left: 16px;
    cursor: pointer;
    align-items: initial;
    justify-content: flex-start;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgba(0,0,0,0.54);
}
.table-items {
    padding-top: 6px;
    padding-right: 24px;
    padding-bottom: 6px;
    padding-left: 16px;
    cursor: pointer;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}
.submit-change-store-button{
    background-color:rgba(0, 0, 0, 0.04);
    padding:12px;
    color:#12a353;
   display: inline;
    border-radius:5px;
}
.message-box{
    color: white;
    height: 20px;
    width: 30%;
    float: right;
    text-align: center;
    margin-right: 4px;
}
.message-box .success-message{
    background-color: #0b8140;
    border-radius:3px;
}
.message-box .error-message{
    background-color: #d40e08;
    border-radius:3px;
}





