.dropdown {
    display: inline;
    border-collapse: collapse;
    width: 170px;
    border-radius:5px;
}
.button{
    width:150px;
    height:40px;
    background-color:rgba(0, 0, 0, 0.04);
    padding:12px;
    color:#616263;
    margin: 0;
    align-items: center;
    justify-content: center;
    border:0px;
   outline:0px;
}
